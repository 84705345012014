// import React from 'react';

function funcdisplayLayer() {
    const body = document.body
    const root = document.getElementById("root")
    const layer = document.getElementById("layer")
    const sidebar = document.getElementById("sidebar")
    const timeline = document.getElementById("timeline")
    const header = document.getElementById("header-sidebar")
    // console.log("HAHA", layer)
    if (layer.classList.contains("display_layer")) {
        body.classList.remove("scrolling");
        root.classList.remove("prevent_scrolling_display");
        layer.classList.remove("display_layer");
        sidebar.classList.remove("active");
        timeline.style.display = "block";
        header.style.backgroundColor = "transparent";
    } else {
        //window.scrollTo(0, body.getBoundingClientRect().height)
        body.classList.add("scrolling")
        root.classList.add("prevent_scrolling_display")
        layer.classList.add("display_layer");
        sidebar.classList.add("active")
        timeline.style.display = "none";
        header.style.backgroundColor = "white";
    }
}
export { funcdisplayLayer };