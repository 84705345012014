import React from 'react';
import '../css/ModalTab.css';
import ReCAPTCHA from "react-google-recaptcha";
import { APP_URL, BACK_URL, PREFIX_IMG } from '../config/env';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactMarkdown from "react-markdown";
import Select from 'react-select';
import $ from "jquery";
import countryList from 'react-select-country-list'

class ModalTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: null,
            lastname: null,
            email: null,
            phone: null,
            legal: false,
            newsletter: false,
            age: null,
            instagram: null,
            height: null,
            waist: null,
            hips: null,
            shoes: null,
            chest: null,
            agenceMere: null,
            showDemand: false,
            type_demand: null,
            comment: null,
            portrait: null,
            profile: null,
            fullBody: null,
            captcha: null,
            form_error: false,
            address: (this.props.address ? this.props.address : "38, rue du Louvre, 75001"),
            selectedCountry: null,
            genderUnit: 'female',
            sizeUnits: "EU",
            gender_value: null,
            showSendDownload: false,
            showSendContact: false,
            showSendScouted: false,
            showSendGetScouted: false,
            selectHeight: [],
            selectChest: [],
            selectWaist: [],
            selectHips: [],
            shoesSizeEU : [
                {value:"35", label:"35 EU"},
                {value:"35.5", label:"35.5 EU"},
                {value:"36", label:"36 EU"},
                {value:"36.5", label:"36.5 EU"},
                {value:"37", label:"37 EU"},
                {value:"37.5", label:"37.5 EU"},
                {value:"38", label:"38 EU"},
                {value:"38.5", label:"38.5 EU"},
                {value:"39", label:"39 EU"},
                {value:"39.5", label:"39.5 EU"},
                {value:"40", label:"40 EU"},
                {value:"40.5", label:"40.5 EU"},
                {value:"41", label:"41 EU"},
                {value:"41.5", label:"41.5 EU"},
                {value:"42", label:"42 EU"},
                {value:"42.5", label:"42.5 EU"},
                {value:"43", label:"43 EU"},
                {value:"43.5", label:"43.5 EU"},
                {value:"44", label:"44 EU"},
                {value:"44.5", label:"44.5 EU"},
                {value:"45", label:"45 EU"},
                {value:"45.5", label:"45.5 EU"},
                {value:"46", label:"46 EU"},
                {value:"46.5", label:"46.5 EU"},
                {value:"47", label:"47 EU"},
                {value:"47.5", label:"47.5 EU"},
                {value:"48", label:"48 EU"},
                {value:"48.5", label:"48.5 EU"}
            ],
            shoesSizeUK : [
                {value:"2.5", label:"2.5 UK"},
                {value:"3", label:"3 UK"},
                {value:"3.5", label:"3.5 UK"},
                {value:"4", label:"4 UK"},
                {value:"4.5", label:"4.5 UK"},
                {value:"5", label:"5 UK"},
                {value:"5.5", label:"5.5 UK"},
                {value:"6", label:"6 UK"},
                {value:"6.5", label:"6.5 UK"},
                {value:"7", label:"7 UK"},
                {value:"7.5", label:"7.5 UK"},
                {value:"8", label:"8 UK"},
                {value:"8.5", label:"8.5 UK"},
                {value:"9", label:"9 UK"},
                {value:"9.5", label:"9.5 UK"},
                {value:"10", label:"10 UK"},
                {value:"10.5", label:"10.5 UK"},
                {value:"11", label:"11 UK"},
                {value:"11.5", label:"11.5 UK"},
                {value:"12", label:"12 UK"},
                {value:"12.5", label:"12.5 UK"},
                {value:"13", label:"13 UK"},
                {value:"13.5", label:"13.5 UK"}
            ],
            shoesSizeUSW : [
                {value:"4", label:"4 US"},
                {value:"4.5", label:"4.5 US"},
                {value:"5", label:"5 US"},
                {value:"5.5", label:"5.5 US"},
                {value:"6", label:"6 US"},
                {value:"6.5", label:"6.5 US"},
                {value:"7", label:"7 US"},
                {value:"7.5", label:"7.5 US"},
                {value:"8", label:"8 US"},
                {value:"8.5", label:"8.5 US"},
                {value:"9", label:"9 US"},
                {value:"9.5", label:"9.5 US"},
                {value:"10", label:"10 US"},
                {value:"10.5", label:"10.5 US"}
            ],
            shoesSizeUSM : [
                {value:"6", label:"6 US"},
                {value:"6.5", label:"6.5 US"},
                {value:"7", label:"7 US"},
                {value:"7.5", label:"7.5 US"},
                {value:"8", label:"8 US"},
                {value:"8.5", label:"8.5 US"},
                {value:"9", label:"9 US"},
                {value:"9.5", label:"9.5 US"},
                {value:"10", label:"10 US"},
                {value:"10.5", label:"10.5 US"},
                {value:"11", label:"11 US"},
                {value:"11.5", label:"11.5 US"},
                {value:"12", label:"12 US"},
                {value:"12.5", label:"12.5 US"},
                {value:"13", label:"13 US"},
                {value:"13.5", label:"13.5 US"},
                {value:"14", label:"14 US"}
            ],
            units: [
                { value: 'EU', label: 'EU' },
                { value: 'UK', label: 'UK' },
                { value: 'US', label: 'US' }
            ],
            gender: [
                { value: 'female', label: 'Female' },
                { value: 'male', label: 'Male' },
                { value: 'other', label: 'Non binary' }
            ],
            options: null,
            value: null,
            typeDemande: [
                { value: 'Scout', label: 'Become a scout' },
                { value: 'Model', label: 'Become a model' },
                { value: 'Other', label: 'Other' }
            ],
        };

        this.handleFileInput = this.handleFileInput.bind(this);
        this.displaySelectUnitM = this.displaySelectUnitM.bind(this);
        this.displaySelectUnitW = this.displaySelectUnitW.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.resetDownloadForm = this.resetDownloadForm.bind(this);
        this.checkCaptcha = this.checkCaptcha.bind(this);
        this.closeTab = this.closeTab.bind(this);
        this.submitContact = this.submitContact.bind(this);
        this.submitScouted = this.submitScouted.bind(this);
        this.submitGetScouted = this.submitGetScouted.bind(this);
        this.submitDownload = this.submitDownload.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    changeHandler = value => {
        this.setState({
            selectedCountry: value.label
        })
    }

    cmToInch(cmValue) {
        return  (cmValue / 2.54).toFixed(1).toString()
    }

    cmToFeet(cmValue) {
      return  (cmValue / 30.48).toFixed(1).toString()
    }

    getHeight() {
        for (let index = 164; index <= 200; index += 0.5) {
            this.setState(prevState => ({
                selectHeight: [...prevState.selectHeight, { value: index, label: `${index} cm / ${this.cmToInch(index)}' / ${this.cmToFeet(index)}''` }]
            }));
        }
    }

    getChest() {
        for (let index = 70; index <= 110; index += 0.5) {
            this.setState(prevState => ({
                selectChest: [...prevState.selectChest, { value: index, label: `${index} cm / ${this.cmToInch(index)}' / ${this.cmToFeet(index)}''` }]
            }));
        }
    }

    getWaist() {
        for (let index = 49.5; index <= 120; index += 0.5) {
            this.setState(prevState => ({
                selectWaist: [...prevState.selectWaist, { value: index, label: `${index} cm / ${this.cmToInch(index)}' / ${this.cmToFeet(index)}''`  }]
            }));
        }
    }

    getHips() {
        for (let index = 69.5; index <= 110; index += 0.5) {
            this.setState(prevState => ({
                selectHips: [...prevState.selectHips, { value: index, label: `${index} cm / ${this.cmToInch(index)}' / ${this.cmToFeet(index)}''`  }]
            }));
        }
    }

    handleSelectChange(event, target) { //Select
        const name = target.name;
        this.setState({
            [name]: event.value
        });
    }

    handleInputChange(event) { //select
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleFileInput(event) {
        const name = event.target.name
        // console.log(name)
        this.setState({
            [name]: event.target.files[0]
        });
    }

    checkCaptcha(value) {
        // console.log("Captcha value:", value);
        this.setState({
            captcha: value
        })
    }

    resetDownloadForm() {
        this.setState({
            firstname: null,
            lastname: null,
            email: null,
            legal: false,
            captcha: null,
            form_error: false,
            phone: null,
            newsletter: false,
            type_demand: null,
            comment: null,
            selectedCountry: null
        })
    }

    resetForm() {
        const download = document.getElementById("form-download");
        const contact = document.getElementById("form-contact");
        const scout = document.getElementById("form-scouted");
        const get_scouted = document.getElementById("form-get-scouted");

        if (download != null) {
            download.reset();
        }
        if (contact != null) {
            contact.reset();
        }
        if (scout != null) {
            scout.reset();
        }
        if (get_scouted != null) {
            get_scouted.reset();
        }
        this.resetDownloadForm()
    }

    submitDownload(event) {
        if (!this.state.legal || !this.state.firstname || !this.state.lastname || !this.state.email || !this.state.captcha)
            this.setState({
                form_error: true
            })
        else
            //console.log("legal | firstname | lastname | email | captcha", this.state)
            this.sendEmail("download")
        event.preventDefault();
    }

    submitContact(event) {
        if (!this.state.firstname || !this.state.lastname || !this.state.email || !this.state.comment || !this.state.type_demand || !this.state.selectedCountry || !this.state.phone)
            this.setState({
                form_error: true
            })
        else
            //console.log("firstname | lastname | email | comment | type_demand | selectedCountry | phone", this.state)
            this.sendEmail("contact")
        event.preventDefault();
    }

    submitScouted(event) {
        if (!this.state.legal || !this.state.firstname || !this.state.lastname || !this.state.email || !this.state.age || !this.state.selectedCountry || !this.state.phone || !this.state.gender_value)
            this.setState({
                form_error: true
            })
        else
            //console.log("legal | firstname | lastname | email | age | selectedCountry | phone | heiht | chest | waist | hips | shoes | portrait | profile | fullBody", this.state)
            this.sendEmail("scouted")
        event.preventDefault();
    }

    submitGetScouted(event) {
        if (!this.state.legal || !this.state.firstname || !this.state.lastname || !this.state.email || !this.state.age || !this.state.gender_value || !this.state.selectedCountry || !this.state.phone || !this.state.height || !this.state.chest || !this.state.waist || !this.state.hips || !this.state.shoes || !this.state.portrait || !this.state.profile || !this.state.fullBody)
            this.setState({
                form_error: true
            })
        else
            //console.log("legal | firstname | lastname | email | age | selectedCountry | phone | heiht | chest | waist | hips | shoes | portrait | profile | fullBody", this.state)
            this.sendEmail("get_scouted")
        event.preventDefault();
    }


    sendEmail(type) {
        if (type === "download") {
            fetch(BACK_URL + "/send-form", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    recipient: this.state.email, // this.state.email user email this.state.email
                    type: type,
                    infos: {
                        firstname: this.state.firstname,
                        lastname: this.state.lastname
                    }
                })
            }).then(response => {
                if (response.ok) {
                    this.setState({
                        showSendDownload: true
                    })
                    this.resetDownloadForm()
                } else
                    alert("ERROR")
            })
        } else if (type === "contact") {
            fetch(BACK_URL + "/send-form", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    //recipient: "app@the-olz.com", // the olz email
                    type: type,
                    infos: {
                        email: this.state.email,
                        firstname: this.state.firstname,
                        lastname: this.state.lastname,
                        phone: this.state.phone,
                        country: this.state.selectedCountry,
                        type_of_demand: this.state.type_demand,
                        comment: this.state.comment
                    }
                })
            }).then(response => {
                if (response.ok) {
                    this.setState({
                        showSendContact: true
                    })
                    this.resetDownloadForm()
                } else {
                    alert("ERROR")
                }
            })
        } else if (type === "scouted") {
            const myForm = document.getElementById('form-scouted');
            const formData = new FormData(myForm);

            fetch(BACK_URL + "/send-form", {
                method: "POST",
                body: formData
            }).then(response => {
                if (response.ok) {
                    this.setState({
                        showSendScouted: true
                    })
                    this.resetDownloadForm()
                } else {
                    alert("ERROR")
                }
            })
        } else if (type === "get_scouted") {
            const myForm = document.getElementById('form-get-scouted');
            const formData = new FormData(myForm);
            fetch(BACK_URL + "/send-form", {
                method: "POST",
                body: formData
            }).then(response => {
                if (response.ok) {
                    this.setState({
                        showSendGetScouted: true
                    })
                    this.resetDownloadForm()
                } else {
                    alert("ERROR")
                }
            })
        }
    }

    closeTab() {
        this.setState({
            showDemand: false,
            showSendDownload: false,
            showSendContact: false,
            showSendScouted: false,
            showSendGetScouted: false
        })
        this.resetDownloadForm()
        this.props.handleClose()
    }

    uploadJquery() {
        $('#upload-profile').change(function() {
            //var i = $(this).prev('label').clone();
            var file = $('#upload-profile')[0].files[0].name;
            $(this).prev('label').text(file);
        });

        $('#upload-portrait').change(function() {
            //var i = $(this).prev('label').clone();
            var file = $('#upload-portrait')[0].files[0].name;
            $(this).prev('label').text(file);
        });

        $('#upload-fbody').change(function() {
            //var i = $(this).prev('label').clone();
            var file = $('#upload-fbody')[0].files[0].name;
            $(this).prev('label').text(file);
        });
    }

    displaySelectUnitW(stylePlaceHolder) {
        if (this.state.sizeUnits === 'EU') {
            return <Select name="shoes" options={this.state.shoesSizeEU} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
        else if (this.state.sizeUnits === 'UK'){
            return <Select name="shoes" options={this.state.shoesSizeUK} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
        else if (this.state.sizeUnits === 'US'){
            return <Select name="shoes" options={this.state.shoesSizeUSW} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
    }

    displaySelectUnitM(stylePlaceHolder) {
        if (this.state.sizeUnits === 'EU') {
            return <Select name="shoes" options={this.state.shoesSizeEU} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
        else if (this.state.sizeUnits === 'UK'){
            return <Select name="shoes" options={this.state.shoesSizeUK} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
        else if (this.state.sizeUnits === 'US'){
            return <Select name="shoes" options={this.state.shoesSizeUSM} placeholder="SHOES" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
        }
    }

    componentDidMount() {
        this.uploadJquery()
        this.getHeight()
        this.getChest()
        this.getWaist()
        this.getHips()
        this.setState({
          options: countryList().getData()
        })
    }

    scout(stylePlaceHolder) {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>DISCOVER MODELS</span>
                            </h2>
                        </div>
                        <div className="row scroll_form" style={{padding: "0px 20px"}}>

                            <h2 className="text_models_infos">Discover models</h2>
                            <p className="text_models_infos tab2columns">To be a fashion model scout means being on the lookout for potential models everywhere: in the street, at events, on social media, the world is your oyster.
                                <br/><br/>
                                The job of a scout is to discover new models, in the community we call them "new faces". Most of the time a scout approaches someone who has no idea of their potential. A scout is a visionary, a forward-thinker who can spot a rough diamond. They must have an “eye” for potential and be ahead of their time. A model scout should be able to see an interesting profile in a crowd, on the street and on social media. Anyone can become a scout, practice makes perfect to develop an eye and spot new faces.
                                <br/>
                                Our model scouts are our eyes. Learn a new profession, as a career or just to earn some extra cash: discover new faces for us. Work your eye. Get a reward.
                                <br/>
                                Individuality, singularity and uniqueness are qualities we look for in our worldwide model search. What is important to us is the spirit and the attitude that the person gives off: positive vibes.
                                <br/><br/>
                                Do you have an eye for potential and you want to detect our next new faces?

                                Apply here, its fun and you'll get a reward if we sign a profile you send us.</p>
                            {
                                !this.state.showSendScouted ?
                                    <form id="form-scouted" className="row content_form" onSubmit={this.submitScouted}>
                                        <div className="col-sm-12 para no-padding-mobile" style={{paddingTop: "0px"}}>
                                            <p></p>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="firstname" required onChange={this.handleInputChange} className="input_index"/>
                                            <label>FIRST NAME</label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="lastname" required onChange={this.handleInputChange} className="input_index"/>
                                            <label>LAST NAME</label>
                                        </div>
                                        <div className="col-sm-2 no-padding-mobile">
                                            <input type="number" name="age" className="age input_index" min="10" max="65" required onChange={this.handleInputChange} />
                                            <label className="ageLabel">AGE</label>
                                        </div>
                                        <div className="col-sm-4 no-padding-mobile">
                                            <Select className="" name="gender_value" options={this.state.gender} placeholder="GENDER" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                        </div>
                                        <div className="col-sm-5 no-padding-mobile">
                                            <Select className="selectCountry" name="selectedCountry" options={this.state.options} placeholder="COUNTRY" onChange={this.changeHandler} styles={stylePlaceHolder}/>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="email" required onChange={this.handleInputChange}/>
                                            <label>EMAIL</label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <PhoneInput containerClass="phone_container" inputClass="phone_input" placeholder="TÉLÉPHONE (OPTIONNEL)" country={'fr'} onChange={phone => this.setState({ phone })}/>
                                        </div>
                                        <input name="type" value="scouted" hidden/>
                                        <div className="col-sm-7 no-padding-mobile">
                                            <input type="text" name="instagram" placeholder="INSTAGRAM" onChange={this.handleInputChange}/>
                                        </div>
                                        <div className="col-sm-12 round1 no-padding-mobile">
                                            <p style={{margin: "0"}}>I read and accept legal policy<span style={{color: "red"}}>*</span></p>
                                        </div>
                                        <div className="col-sm-12 round no-padding">
                                            <input id="checkbox1" name="legal" className={"form_checkbox " + this.state.hasBorder} type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox1"></label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <p style={{margin: "0"}}>Suscribe to the newsletter</p>
                                        </div>
                                        <div className="col-sm-12 round no-padding">
                                            <input id="checkbox" name="newsletter" className="form_checkbox" type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox"></label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile" style={{textAlign: "center"}}>
                                            <p style={{color: "red", textAlign: "center"}}>{this.state.form_error ? "VOUS DEVEZ SPÉCIFIER UNE VALEUR POUR LE(S) CHAMPS OBLIGATOIRE*" : ""}</p>
                                        </div>
                                        <div className="col-sm-8 submit no-padding-mobile">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                        <div className="col-sm-12 text no-padding-mobile">
                                            <p>We wrote to you, please check your emails</p>
                                        </div>
                                    </form>
                                :
                                    <div className="col-sm-12 email_send">
                                        <div className="email_send_box">
                                            <img src={require('../images/check_vert.svg')} alt=""/>
                                            <h2>VOTRE PROFILE A BIEN ÉTÉ ENVOYÉ</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    getScouted(stylePlaceHolder) {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span> Become a model</span>
                            </h2>
                        </div>
                        <div className="row scroll_form" style={{padding: "0px 20px"}}>

                            <h2 className="text_models_infos">Become a model</h2>
                            <p className="text_models_infos tab2columns">

                                Are you unique and you want to become a model?
                                <br/><br/>
                                Individuality, singularity and uniqueness are qualities we look for in our worldwide model search. What is important to us is the spirit and the attitude that the person gives off: positive vibes.
                                <br/><br/>
                                We are looking for tall, young models with fresh faces and natural beauty. We are also very interested in older profiles, especially atypical and surprising beauties of all nationalities, ethnicities, skin colors and genders.
                                You can apply to become a model and sign with our agency by sending your photos, height and measurements.
                                <br/>
                                VERY IMPORTANT : You must NOT already have a mother agency. And remember: Love yourself!
                                <br/><br/>
                                Fill-in the form below and submit your photos.
                            </p>
                            {
                                !this.state.showSendGetScouted ?
                                    <form id="form-get-scouted" className="row content_form" onSubmit={this.submitGetScouted}>
                                        <div className="col-sm-12 para no-padding-mobile" style={{paddingTop: "0px"}}>
                                            <p></p>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="firstname" required onChange={this.handleInputChange} className="input_index"/>
                                            <label>FIRST NAME</label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="lastname" required onChange={this.handleInputChange} className="input_index"/>
                                            <label>LAST NAME</label>
                                        </div>
                                        <div className="col-sm-2 no-padding-mobile">
                                            <input type="number" name="age" className="age input_index" min="10" max="65" required onChange={this.handleInputChange} />
                                            <label className="ageLabel">AGE</label>
                                        </div>
                                        <div className="col-sm-4 no-padding-mobile">
                                            <Select className="" name="gender_value" options={this.state.gender} placeholder="GENDER" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                        </div>
                                        <div className="col-sm-5 no-padding-mobile">
                                            <Select className="selectCountry" name="selectedCountry" options={this.state.options} placeholder="COUNTRY" onChange={this.changeHandler} styles={stylePlaceHolder}/>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <input type="text" name="email" required onChange={this.handleInputChange}/>
                                            <label>EMAIL</label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <PhoneInput containerClass="phone_container" inputClass="phone_input" placeholder="TÉLÉPHONE (OPTIONNEL)" country={'fr'} onChange={phone => this.setState({ phone })}/>
                                        </div>
                                        <input name="type" value="get_scouted" hidden/>
                                        {/* <div className="col-sm-12">
                                            <input type="text" placeholder="If you have a mother agency please add it here. Please note, if you do have a mother agency you may need not apply unless you wish to change your mother agency." name="agenceMere" onChange={this.handleInputChange}/>
                                        </div> */}
                                        <div className="col-sm-7 no-padding-mobile">
                                            <input type="text" name="instagram" placeholder="INSTAGRAM" onChange={this.handleInputChange}/>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile para">
                                            <p>MEASUREMENTS</p>
                                        </div>
                                        {/* <div className="col-sm-5">
                                            <Select options={this.state.gender} name="genderUnit" placeholder="GENDER UNIT" onChange={this.handleSelectChange} styles={stylePlaceHolder} />
                                        </div> */}
                                        <div className="col-sm-5 no-padding-mobile">
                                            <Select options={this.state.units} name="sizeUnits" placeholder="SIZE UNITS" onChange={this.handleSelectChange} styles={stylePlaceHolder} />
                                        </div>
                                        <div className="row mesure">
                                            <div className="col-sm-4 no-padding-mobile">
                                                <Select name="height" options={this.state.selectHeight} placeholder="HEIGHT" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                            </div>
                                            <div className="col-sm-4 no-padding-mobile">
                                                <Select name="chest" options={this.state.selectChest} placeholder="CHEST" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                            </div>
                                            <div className="col-sm-4 no-padding-mobile">
                                                <Select name="waist" options={this.state.selectWaist} placeholder="WAIST" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                            </div>
                                            <div className="col-sm-4 no-padding-mobile">
                                                <Select name="hips" options={this.state.selectHips} placeholder="HIPS" onChange={this.handleSelectChange}  styles={stylePlaceHolder}/>
                                            </div>
                                            <div className="col-sm-4 no-padding-mobile">
                                                {
                                                    this.state.genderUnit === "female" ?
                                                        this.displaySelectUnitW(stylePlaceHolder)
                                                    :
                                                        this.displaySelectUnitM(stylePlaceHolder)
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile para">
                                            <p>SUBMIT YOUR PHOTOS TO BECOME A MODEL</p>
                                        </div>
                                        <div className="col-sm-4 no-padding-mobile">
                                            <label htmlFor="upload-portrait" className=" custom-file-upload">
                                                <i className="fa fa-cloud-upload"></i>
                                                    PORTRAIT
                                            </label>
                                            <input id="upload-portrait" name="portrait" type="file" onChange={this.handleFileInput} />
                                        </div>
                                        <div className="col-sm-4 no-padding-mobile">
                                            <label htmlFor="upload-profile" className=" custom-file-upload ">
                                                <i className="fa fa-cloud-upload"></i>
                                                    PROFILE
                                            </label>
                                            <input id="upload-profile" name="profile" type="file" onChange={this.handleFileInput} />
                                        </div>
                                        <div className="col-sm-4 no-padding-mobile">
                                            <label htmlFor="upload-fbody" className=" custom-file-upload">
                                                <i className="fa fa-cloud-upload"></i>
                                                FULL BODY
                                            </label>
                                            <input id="upload-fbody" name="fullBody" type="file" onChange={this.handleFileInput} />
                                        </div>
                                        <div className="col-sm-12 polas no-padding-mobile">
                                            <p>PHOTO GUIDELINES: Natural light - No filters - Clean / natural face - No make-up - Hair in the back - Tight clothing (skinny jeans + black or white t-shirts or tank tops)</p>
                                        </div>
                                        <div className="col-sm-12 round1 no-padding-mobile">
                                            <p style={{margin: "0"}}>I have read and accept the legal notice and privacy policy<span style={{color: "red"}}>*</span></p>
                                        </div>
                                        <div className="col-sm-12 round no-padding">
                                            <input id="checkbox1" name="legal" className={"form_checkbox " + this.state.hasBorder} type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox1"></label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile">
                                            <p style={{margin: "0"}}>Suscribe to the newsletter</p>
                                        </div>
                                        <div className="col-sm-12 round no-padding">
                                            <input id="checkbox" name="newsletter" className="form_checkbox" type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox"></label>
                                        </div>
                                        <div className="col-sm-12 no-padding-mobile" style={{textAlign: "center"}}>
                                            <p style={{color: "red", textAlign: "center"}}>{this.state.form_error ? "VOUS DEVEZ SPÉCIFIER UNE VALEUR POUR LE(S) CHAMPS OBLIGATOIRE*" : ""}</p>
                                        </div>
                                        <div className="col-sm-8 submit no-padding-mobile">
                                            <button type="submit">SUBMIT</button>
                                        </div>
                                        <div className="col-sm-12 text no-padding-mobile">
                                            <p>We’ll be back in touch ASAP regarding your model submission</p>
                                        </div>
                                    </form>
                                :
                                    <div className="col-sm-12 email_send">
                                        <div className="email_send_box">
                                            <img src={require('../images/check_vert.svg')} alt=""/>
                                            <h2>THANK YOU  FOR SUBMITTING YOUR PROFILE. WE WILL GET BACK TO YOU ONCE WE HAVE REVIEWED IT WITH THE TEAM.</h2>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    download() {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>DOWNLOAD THE APP FOR FREE</span>
                            </h2>
                        </div>
                        <div className="row download_scroll">
                            { !this.state.showSendDownload ?
                                <div className="col-sm-12 download_tab">
                                    <h2>DOWNLOAD THE APP FOR FREE</h2>
                                    <p className='tab2columns'>The first worldwide model discovery App.
                                        <br/>
                                        Our model discovery App is the first of its kind in the world. Anyone can use it, it's so simple. Aspiring models can send in their application and model scouts can send us their discoveries via the app from all over the world.
                                        <br/>
                                        Ever thought of becoming a fashion model scout as an extra?
                                        <br/>
                                        Learn a new profession, as a career or just to earn some extra cash: discover new faces for us. Work your eye. Get a reward. It’s very satisfying to find a new face and kick-start a modeling career, especially when the model becomes a success.
                                        <br/>
                                        Follow our model community on the App.
                                        <br/>
                                        Download THE.Ølz Worldwide Model Scouting Application for free and get started!
                                        <br/><br/>
                                        Fill-in this short form and we’ll send you the link to download the App by email.
                                    </p>
                                    <form id="form-download" className="row content_form" onSubmit={this.submitDownload}>
                                        <div className="col-sm-12 no-padding">
                                            <input type="text" name="firstname" required onChange={this.handleInputChange}/>
                                            <label>FIRST NAME</label>
                                        </div>
                                        <div className="col-sm-12 no-padding">
                                            <input type="text" name="lastname" required onChange={this.handleInputChange}/>
                                            <label>LAST NAME</label>
                                        </div>
                                        <div className="col-sm-12 no-padding">
                                            <input type="text" name="email" required onChange={this.handleInputChange}/>
                                            <label>EMAIL</label>
                                        </div>
                                        <div className="col-sm-6 no-padding round">
                                            <p style={{margin: "0"}}>I read and accept legal policy<span style={{color: "red"}}>*</span></p>
                                            <input id="checkbox_legal" name="legal" className={"form_checkbox " + this.state.hasBorder} type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox_legal"></label>
                                        </div>
                                        <div className="col-sm-6 no-padding round">
                                            <p style={{margin: "0"}}>Suscribe to the newsletter</p>
                                            <input id="checkbox_news" name="newsletter" className="form_checkbox" type="checkbox" onChange={this.handleInputChange}/>
                                            <label htmlFor="checkbox_news"></label>
                                        </div>
                                        <div className="col-sm-12">
                                            <ReCAPTCHA className="download_captcha" size="normal" sitekey="6LeST_YZAAAAAISZqB_apFytRk7EKh0mLLYMDCtk" onChange={this.checkCaptcha}/>
                                        </div>
                                        <div className="col-sm-12" style={{textAlign: "center"}}>
                                            <p style={{color: "red", textAlign: "center"}}>{this.state.form_error ? "VOUS DEVEZ SPÉCIFIER UNE VALEUR POUR LE(S) CHAMPS OBLIGATOIRE*" : ""}</p>
                                        </div>
                                        <div className="col-sm-12 no-padding">
                                            <div className="row">
                                                <div className="col-sm-6 no-padding-left" style={{paddingBottom: "8px"}} >
                                                    {/* <input type="submit" value="Envoyer" /> */}
                                                    <button type="submit">SEND</button>
                                                </div>
                                                <div className="col-sm-6 no-padding-right" style={{paddingBottom: "8px"}} >
                                                    <button type="button" onClick={this.resetForm}>RESET</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            :
                                <div className="col-sm-12 email_send">
                                    <div className="email_send_box">
                                        <img src={require('../images/check_vert.svg')} alt=""/>
                                        <h2> THANKS FOR REACHING OUT. WE'LL BE BACK IN TOUCH SOON. </h2>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // affichage de l'onglet contact
    first_form_contact() {
        return (
            <>
                <div className="row contact_tab content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 no-padding">
                        <h2>CONTACT INFO</h2>
                    </div>
                    <div className="col-sm-12 array_tab" style={{ marginBottom: '50px' }}>
                        <div className="row">
                            <h2>
                                <span>contact</span>
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-6 box_information1">
                                <p>THE.Ølz agency is open from Monday to Friday from 10 AM until 7 PM. Our team are available to answer your questions and take appointments by telephone: <br/><span className="info_contact">{this.props.phone_number}</span></p>
                                <br/>
                                <h3>E-MAIL</h3>
                                <p>You may send us an email to: <br/><a className="info_contact" href="mailto:email">{this.props.email}</a></p>
                            </div>
                            <div className="col-12 col-md-6 box_information">
                                <div className="row">
                                    {/* <div className="col-sm-12 no-padding">
                                        <h3>MESSENGER & WHATSAPP</h3>
                                    </div>
                                    <div className="col-sm-12 no-padding">
                                        <a href="#">MESSENGER</a>
                                        <br/>
                                        <a href="#">WHATSAPP</a>
                                    </div> */}
                                    <div className="col-sm-12 no-padding">
                                        <h3 className="showDemand" onClick={() => this.setState({ showDemand: true })}>CLICK HERE TO SEND YOUR REQUEST</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 array_tab">
                        <div className="row">
                            <h2>
                                <span>LOCALISATION</span>
                            </h2>
                        </div>
                        <div className="row" style={{width : "100%"}}>
                            <div className="col-sm-12 contact_address">
                                <p>{this.state.address}</p>
                            </div>
                            <div className="col-sm-12 box_information">
                                <div>
                                    <iframe
                                        title={"The.Olz Location"}
                                        style={{width:"100%", height:"31vh", border: "none"}}
                                        src={"https://www.google.com/maps/embed/v1/search?key=AIzaSyDifUefqo2B6VSTM4E7X7up9vsl9q5L1Lc&q=" + this.state.address} allowFullScreen>
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    // si l'utilisateur a cliquer sur "Envoyer une demande"
    second_form_contact(stylePlaceHolder) {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={() => this.setState({ showDemand: false })} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>demande</span>
                            </h2>
                        </div>
                        <div className="row scroll_form">
                            <div className="col-sm-12 download_tab">
                                <h2>SEND A REQUEST</h2>
                                <form id="form-contact" className="row content_form" onSubmit={this.submitContact}>
                                    <div className="col-sm-12">
                                        <input type="text" name="firstname" required onChange={this.handleInputChange}/>
                                        <label>FIRST NAME</label>
                                    </div>
                                    <div className="col-sm-12">
                                        <input type="text" name="lastname" required onChange={this.handleInputChange}/>
                                        <label>LAST NAME</label>
                                    </div>
                                    <div className="col-sm-12">
                                        <input type="text" name="email" required onChange={this.handleInputChange}/>
                                        <label>EMAIL</label>
                                    </div>
                                    <div className="col-sm-12">
                                        <PhoneInput containerClass="phone_container" inputClass="phone_input" placeholder="TÉLÉPHONE (OPTIONNEL)" country={'fr'} onChange={(phone, country, e, formattedValue) => this.setState({phone: formattedValue})}/>
                                    </div>
                                    <div className="col-sm-12">
                                        <Select className="selectCountries" options={this.state.options} placeholder="COUNTRY" onChange={this.changeHandler} styles={stylePlaceHolder}/>
                                    </div>
                                    <div className="col-sm-7">
                                        <Select options={this.state.typeDemande} className="selectDemande" name="type_demand" placeholder="TYPE OF REQUEST" onChange={this.handleSelectChange} styles={stylePlaceHolder}/>
                                    </div>
                                    <div className="col-sm-12" style={{marginTop: "3vh"}}>
                                        <textarea name="comment" maxLength="200" required onChange={this.handleInputChange}></textarea>
                                        <label>COMMENT</label>
                                        {/*<label className="length-COMMENTAIRE">0/200</label>*/}
                                    </div>
                                    <div className="col-sm-12" style={{textAlign: "center"}}>
                                        <p style={{color: "red", textAlign: "center"}}>{this.state.form_error ? "VOUS DEVEZ SPÉCIFIER UNE VALEUR POUR LE(S) CHAMPS OBLIGATOIRE*" : ""}</p>
                                    </div>
                                    <div className="col-sm-12 no-padding">
                                        <div className="row">
                                            <div className="col-sm-6" style={{paddingBottom: "8px"}} >
                                                <button type="submit">SEND</button>
                                            </div>
                                            <div className="col-sm-6" style={{paddingBottom: "8px"}}>
                                                <button type="button" onClick={this.resetForm}>RESET</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    third_form_contact() {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={() => this.setState({showDemand: false, showSendContact: false})} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab">
                        <div className="row">
                            <h2>
                                <span>application</span>
                            </h2>
                        </div>
                        <div className="row" style={{width: "100%"}}>
                            <div className="col-sm-12 email_send">
                                <div className="email_send_box">
                                    <img src={require('../images/check_vert.svg')} alt=""/>
                                    <h2>UN LIEN DE TÉLÉCHARGEMENT A ÉTÉ ENVOYÉ SUR VOTRE BOITE MAIL</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    contact(stylePlaceHolder) {
        if (this.state.showDemand === false)
            return this.first_form_contact()
        else if (this.state.showDemand === true && this.state.showSendContact === false)
            return this.second_form_contact(stylePlaceHolder)
        else if (this.state.showDemand === true && this.state.showSendContact === true)
            return this.third_form_contact()
    }

    about() {
        return (
            <>
                <div className="row content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>ABOUT</span>
                            </h2>
                        </div>
                        <div className="row scroll_form">
                            <div className="col-sm-12 download_tab">
                                <h2>THE.ØLZ MODEL SCOUTING & MOTHER AGENCY</h2>
                                <ReactMarkdown
                                    className="tab2columns"
                                    allowDangerousHtml={true}
                                    source={this.props["about"].content}
                                    transformImageUri={uri =>
                                        uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                    }
                                />
                                {/* <video style={{width: "100%"}} controls>
                                    <source src={PREFIX_IMG + this.props["about"].video.url} type="video/webm"/>
                                </video> */}
                                <video src={PREFIX_IMG + this.props["about"].video.url} type="video/mp4" style={{height: "52vh", outline:'none'}} controls autoPlay muted fluid="false"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    legal_privacy() {
        return (
            <>
                <div className="row legal_tab content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>privacy notice</span>
                            </h2>
                        </div>
                        <div className="row scroll_form">
                            <div className="col-sm-12 legal_tab">
                                <ReactMarkdown
                                    source={this.props["legals"][0].content}
                                    transformImageUri={uri =>
                                        uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    legal_term() {
        return (
            <>
                <div className="row legal_tab content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>term of use</span>
                            </h2>
                        </div>
                        <div className="row scroll_form">
                            <div className="col-sm-12 legal_tab">
                                <ReactMarkdown
                                    source={this.props["legals"][1].content}
                                    transformImageUri={uri =>
                                        uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    legal_notice() {
        return (
            <>
                <div className="row legal_tab content_tab" style={{height: "100%"}}>
                    <div id="btn-close-tab" onClick={this.closeTab} className={`close_tab ${this.props.isOpen && "active"}`}></div>
                    <div className="col-sm-12 array_tab" style={{height: "100%"}}>
                        <div className="row">
                            <h2>
                                <span>legal notice</span>
                            </h2>
                        </div>
                        <div className="row scroll_form">
                            <div className="col-sm-12 legal_tab">
                                <ReactMarkdown
                                    source={this.props["legals"][2].content}
                                    transformImageUri={uri =>
                                        uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderTab(tabName, stylePlaceHolder) {
        switch(tabName) {
            case 'scouted':
                return this.scout(stylePlaceHolder);
            case 'getscouted':
                return this.getScouted(stylePlaceHolder);
            case 'download':
                return this.download();
            case 'contact':
                return this.contact(stylePlaceHolder);
            case 'about':
                return this.about();
            case 'legal_privacy':
                return this.legal_privacy();
            case 'legal_term':
                return this.legal_term();
            case 'legal_notice':
                return this.legal_notice();
            default:
                return this.scout();
        }
    }

    render() {
        const tabName = this.props.currentTab;
        const SelectPlaceHolderStyle = {
            placeholder: (defaultStyles) => {
                return {
                    ...defaultStyles,
                    'color': 'black',
                    'fontFamily': 'aktiv-grotesk',
                    'fontWeight': '400',
                    'fontSize': '0.9rem',
                }
            }
        }
        return (
            <>
                <div className={`modal-tab ${this.props.isOpen && "active"}`}>
                    {this.renderTab(tabName, SelectPlaceHolderStyle)}
                </div>
            </>
        );
    }
}

export default ModalTab;
