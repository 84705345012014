import React from 'react';
import Header from './Header.js';
import Content from './Content.js';
// import Footer from './Footer.js';
import { APP_URL } from '../config/env';
// import {funcdisplayLayer} from "./utils.js";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerInfos: null,
            BodyInfos: null,
            FooterInfos: null
        };
    }

    async getHomepageInfos() {
        const response = await fetch(APP_URL + "/homepage", {
            method: "GET",
            headers: {
                "accept": "Application/json"
            }
        });
        const json = await response.json();
        this.setState({
            headerInfos: json.header,
            BodyInfos: json.body,
            FooterInfos: json.footer
        })
    }

    async componentDidMount() {
        this.getHomepageInfos()
    }
    // onClick={funcdisplayLayer}
    render() {
        return (
            <>
                <div id="layer" className="layer"></div>
                {this.state.headerInfos && <Header {...this.state.headerInfos} {...this.props}/>}
                {this.state.BodyInfos && <Content {...this.state.headerInfos} {...this.state.BodyInfos.reverse()}/>}
            </>
        );
    }
}

export default LandingPage;