import React from 'react';
import '../css/App.css';
import '../css/Content.css';
import DisplayGrid from "./DisplayGrid";
import ModalImage from './ModalImage';
import ModalTab from './ModalTab';
import Footer from './Footer';
import Timeline from './Timeline';
import $ from "jquery";
import CookieConsent from "react-cookie-consent";
import {funcdisplayLayer} from "./utils.js";

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showTab: false,
            currentMedia: null,
            currentTab: null,
            showCross: true,
            showSocial: false,
            showLegal: false,
            address: this.props.address,
            email: this.props.email,
            institutional_video: this.props.institutional_video,
            phone_number: this.props.phone_number,
            scrollDone : false,
            pageHeight : window.innerHeight,
            responsive: false
        };

        this.openModalImage = this.openModalImage.bind(this);
        this.openModalTab = this.openModalTab.bind(this);
        this.handleResponsive = this.handleResponsive.bind(this);
    }

    openModalImage(elem) {
        console.log(this.props[elem.currentTarget.dataset.id]);
        this.setState({
            showModal: true,
            // currentImage: this.props?.[0].images_news[e.currentTarget.dataset.id - 1]
            // currentImage: e.currentTarget.dataset.id
            currentMedia: this.props[elem.currentTarget.dataset.id]
        });
    }

    openModalTab(elem) {
        this.setState({
            showTab: true,
            showCross: false,
            currentTab: elem.currentTarget.dataset.id
        });
    }

    uploadJquery() {
        $(" .image, .video ").on("click", function(e) {
            // une div
            if ($(window).width() < 1200) {
                $(" body ").addClass("noscroll")
                document.body.addEventListener('touchmove', e.preventDefault, { passive: false });
            }
            $(" #grid_news ").removeClass("wrapper")
            $(" #grid_news ").addClass("wrapper_modalOpen")
            $(" .carousel ").removeClass("print_array")
            $(" .carousel ").addClass("carousel_two_col")
            $(" .article ").removeClass("print_array")
            $(" .article ").addClass("article_two_col")
            $(" .focus ").removeClass("print_array")
            $(" .focus ").addClass("video_two_col")

            //Apply responsive
            if($(" #grid_news ").width() < 684){
                $(" .post_portrait ").addClass("image_visible");
                $(" .post_landscape ").addClass("image_visible");
            }

            //Get clicked element
            const mediaClicked = $(this).attr("data-id");
            //go to clicked element
            if(document.getElementById("container_" + mediaClicked)) {
                document.getElementById("container_" + mediaClicked).scrollIntoView(true);
            } else {
                document.getElementById("div_" + mediaClicked).scrollIntoView(true);
                $(" #video ").trigger("play");
            }

        })

        $(".getScoutedFooter").on("click", function(){
            $("html, body").animate({ scrollTop: $(document).height() }, 1000).promise().then(function() {
                $("#hamburger").trigger('click').promise().then(function() {
                    $("#tab_getScouted").trigger('click');
                })
            });
        });

        $(".scoutFooter").on("click", function(){
            $("html, body").animate({ scrollTop: $(document).height() }, 1000).promise().then(function() {
                $("#hamburger").trigger('click').promise().then(function() {
                    $("#tab_scout").trigger('click');
                })
            });
        });

        $(".cookies-policy").on("click", function(){
            $("html, body").animate({ scrollTop: $(document).height() }, 2000).promise().then(function() {
                $("#hamburger").trigger('click').promise().then(function() {
                    $("#legal-cookies").trigger('click');
                })
            });
        });

        $(".accept-cookies").on("click", function(){
            $(".content-cookies").hide()
        });

        $("#layer").on("click", function() {
            $("#btn-close-tab").trigger('click').promise().then(function() {
                $("#btn-close-sidebar").trigger('click');
            })
        });

        $('#grid_news').on('scroll', function () {
            $('#timeline').scrollTop($(this).scrollTop());
        });
    }

    handleResponsive(event){
        const body = document.body;
        if(body.clientWidth < 1200 || $(" #grid_news ").width() < 684){
            this.setState({responsive: true});
        }else{
            this.setState({responsive: false});
            //reset element
            $(" .post_portrait ").removeClass("image_visible");
            $(" .post_landscape ").removeClass("image_visible");
        }

        this.disableScroll(body);
    }

    doScroll(body, headerHeight, scrollY) {

            if ($('#scrollSmooth').length && scrollY >= (headerHeight / 7)) {
                $("html, body").stop().animate({scrollTop: $('#scrollSmooth').offset().top}, 500);
                body.classList.add("stop-scrolling");
            }
    }

    disableScroll(body) {

        let headerHeight = $('#header-top').height();
        let scrollY = Math.round(window.scrollY);


        if ($(window).width() >= 1200) {
            this.doScroll(body, headerHeight, scrollY);

        } else {
            //alert('EN dessous de 1200 ! ');
            //await scrollToNews();
            body.classList.remove("unScrollAfterHome");
            body.classList.remove("stop-scrolling");
        }
    }

    componentDidMount() {
        this.uploadJquery();
        //Determine screen size display adapted view
        this.handleResponsive(null);
        window.removeEventListener('resize', ()=>{});
        window.addEventListener('resize', this.handleResponsive);
    }

    render() {
        const news = this.props;
        const nb_elem = Object.keys(news).length
        return (
            <>
                <div className="content-box">
                    <Timeline id="timeline" nbElem={nb_elem}/>
                    <div id="header-sidebar" className="content-header">
                        <a href={window.location.href}>
                            <img src={require('./picto_noir.svg')} width="100" height="100" className="logo_first" alt=""/>
                        </a>
                        <div id="hamburger" className="header_1" onClick={funcdisplayLayer}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div id="sidebar" className="sidebar-menu">
                            <div className="sidebar-menu-tabs">
                                { this.state.showCross ? <label id="btn-close-sidebar" htmlFor="nav_trigger" className="nav_close_icon" onClick={funcdisplayLayer}></label> : null }
                                <a className="nav-link" href="/">Homepage</a>
                                <div className="nav-link" data-id="scouted" onClick={this.openModalTab} id="tab_scout">Discover models</div>
                                <div className="nav-link" data-id="getscouted" onClick={this.openModalTab} id="tab_getScouted">Become a model</div>
                                <div className="nav-link" data-id="download" onClick={this.openModalTab} id="tab_download">Download THE.App</div>
                                <div className="nav-link2">
                                    <img src={require('./logo.svg')} width="100" height="100" className="logo" alt=""/>
                                    <div className="separation"></div>
                                </div>
                                <div className="nav-link" data-id="contact" onClick={this.openModalTab} id="tab_contact">Contact</div>
                                <div className="nav-link" data-id="about" onClick={this.openModalTab} id="tab_about">About</div>
                                <div className="nav-link" onClick={() => this.setState({ showSocial: !this.state.showSocial })} id="tab_sNetwork">Social media</div>
                                <div className={this.state.showSocial ? "show_social" : "hide_social"}>
                                    <a className="dropdown-item" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/theolz.scouting/?hl=fr">instagram</a>
                                    <a className="dropdown-item" rel="noopener noreferrer" target="_blank" href="https://www.tiktok.com/@theolz.scouting?lang=fr">TikTok</a>
                                    <a className="dropdown-item" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/the-%C3%B8lz/?originalSubdomain=fr">LinkedIn</a>
                                </div>
                                <div className="nav-link" data-id="legal" onClick={() => this.setState({ showLegal: !this.state.showLegal })}>Legal</div>
                                <div className={this.state.showLegal ? "show_legal" : "hide_legal"}>
                                    <div className="dropdown-item" data-id="legal_notice" onClick={this.openModalTab}>legal notice</div>
                                    <div className="dropdown-item" data-id="legal_privacy" onClick={this.openModalTab}>privacy notice</div>
                                    <div className="dropdown-item" data-id="legal_term" onClick={this.openModalTab}>term of use</div>
                                </div>
                            </div>
                            <img src={require('./logo_noir.svg')} className="logo_footer_first" alt=""/>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div id="grid_news" className="wrapper">
                           <DisplayGrid news={news} state={this.state.responsive} todo={this.openModalImage} />
                        </div>
                        {this.state.currentMedia && <ModalImage {...this.state} isOpen={this.state.showModal} handleClose={() => this.setState({ showModal: false })}/>}
                    </div>
                    <ModalTab {...this.state} {...this.props} isOpen={this.state.showTab} handleClose={() => this.setState({ showTab: false, showCross: true })}/>
                    <Footer />
                    <CookieConsent containerClasses="react-cookie-consent" location="bottom" cookieName="myAwesomeCookieName2" style={{ background: "#0000008a" }} buttonStyle={{ background: "#000000", color: "#ffffff", 	border: "0px", padding: "7px 24px", margin: "15px", font : "16px" }} expires={150}>This website uses cookies to enhance the user experience.</CookieConsent>
                </div>
            </>
        );
    }
}

export default Content;
