import React from 'react';
import '../css/ModalImage.css';
import CustomCarousel from './CustomCarousel';
import $ from "jquery";
import {PREFIX_IMG} from "../config/env";

class ModalImage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentMedia: props.currentMedia
        }
    }

    uploadJquery() {
        $(" #close_modal ").on("click", function (e) {
            if ($(window).width() < 1200) {
                $(" body ").removeClass("noscroll")
                document.body.removeEventListener('touchmove', e.preventDefault);
            }
            $(" #grid_news ").removeClass("wrapper_modalOpen")
            $(" #grid_news ").addClass("wrapper")
            $(" .carousel ").removeClass("carousel_two_col")
            $(" .carousel ").addClass("print_array")
            $(" .article ").removeClass("article_two_col")
            $(" .article ").addClass("print_array")
            $(" .focus ").removeClass("video_two_col")
            $(" .focus ").addClass("print_array")

            //reset element
            $(" .post_portrait ").removeClass("image_visible");
            $(" .post_landscape ").removeClass("image_visible");
            $(" #video ").trigger("pause");

        })
    }

    componentDidMount() {
        this.uploadJquery()

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentMedia !== prevProps.currentMedia){
            this.setState({currentMedia: this.props.currentMedia});
        }
    }

    render() {
        return (
            <>
                <div className={`modal-img ${this.props.isOpen && "active"}`}>
                    <div id="close_modal" onClick={() => this.props.handleClose()}
                         className={`custom_cross ${this.props.isOpen && "active"}`}></div>
                    <div className="modal_carousel row">
                        <div className="col-sm-12 modal-text-zone" style={{padding: '0px'}}>
                            <p>{this.state.currentMedia.__component === 'page.posts'
                                ? this.state.currentMedia.author
                                : this.state.currentMedia.video_loop.name.split(".")[0]}</p>
                        </div>
                        {this.state.currentMedia.__component === 'page.posts'
                            ? <div className="col-sm-12 modal-carousel-zone">
                                <CustomCarousel {...this.state} type="modal" nbElem={1}/>
                            </div>
                            : <div className="col-sm-12 modal-video">
                                <video id="video" src={PREFIX_IMG + this.state.currentMedia.video_loop.url} type="video/mp4" className="" autoPlay fluid="false" style={{cursor: "pointer"}}/>
                            </div>}

                    </div>
                </div>
            </>
        );
    }
}

export default ModalImage;