module.exports = {
    // local
    // APP_URL: "http://localhost:1337",
    // BACK_URL: "http://localhost:4000",
    // PREFIX_IMG: "http://localhost:1337"

    // prod
    APP_URL: "https://the-olz-strapi.herokuapp.com",
    BACK_URL: "https://the-olz-api.herokuapp.com",
    PREFIX_IMG: ""
};