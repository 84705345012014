import React from 'react';
import '../css/CustomCarousel.css'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { PREFIX_IMG } from '../config/env';

class CustomCarousel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images_modal: props.currentMedia,
            images: props,
            type: props.type,
            nbElem: props.nbElem,
            displayCarousel: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentMedia !== prevProps.currentMedia){
            this.setState({
                images_modal: this.props.currentMedia,
                images: this.props,
                type: this.props.type,
                nbElem: this.props.nbElem,
                displayCarousel: false
            });
            //Wait and switch display carousel to true
            setTimeout(() => {
                this.setState({displayCarousel: true});
            }, 100);
        }
    }

    showModal(obj, index, totalElem) {
        // console.log(obj)
        return (
            <div id={obj.id} key={obj.id} className={"box_image " + (obj.height >= obj.width ? "portrait" : "landscape")}>
                <img className="img-carousel" src={PREFIX_IMG + obj.formats?.large?.url && PREFIX_IMG + obj.url} alt=""/>
                <div className="col-sm-12 current_index">
                    <p>{(index + 1) + "/" + totalElem}</p>
                </div>
            </div>
        )
    }

    showCarousel(obj) {
        return (
            <div key={obj.id} className="col-sm-12">
                <img className="img-carousel" src={PREFIX_IMG + obj.formats?.large?.url && PREFIX_IMG + obj.url} alt=""/>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true;
    }


    render() {
        const responsive = {
            superLargeDesktop: {
                breakpoint: { max: 4000, min: 2000 },
                items: this.state.nbElem
            },
            desktop: {
                breakpoint: { max: 2000, min: 1024 },
                items: this.state.nbElem > 1 ? this.state.nbElem - 1 : 1
            },
            tablet: {
                breakpoint: { max: 1024, min: 768 },
                items: this.state.nbElem > 2 ? this.state.nbElem - 2 : 1
            },
            mobile: {
                breakpoint: { max: 767, min: 0 },
                items: 1
            }
        };

        const ModalCarousel = ({ next, previous }) => {
            return (
                <div className="carousel-button-group">
                    <button className="custom-button-prev" onClick={() => previous()}>PREVIOUS</button>
                    <button className="custom-button-next" onClick={() => next()}>NEXT</button>
                </div>
            );
        };


        const NewsCarousel = ({ next, previous }) => {
            return (
                <div className="carousel-button-group">
                    <button className="custom-button-prev" onClick={() => previous()} aria-label="prev button"></button>
                    <button className="custom-button-next" onClick={() => next()} aria-label="next button"></button>
                </div>
            );
        };

        return (
            <div className={"carousel_" + this.state.type}>
                {
                    this.state.type === "modal" ?
                        (this.state.displayCarousel && <Carousel className={"row"} beforeChange={this.beforeChange} arrows={false} renderButtonGroupOutside={true} customButtonGroup={ this.state.images_modal?.images?.length > 1 ? <ModalCarousel/> : null } responsive={responsive} infinite={false} draggable={true} swipeable={true}>
                            {
                                Object.keys(this.state.images_modal.images).map((key, index) => {
                                    var toDisplay = null;
                                    if (typeof this.state.images_modal.images[key] === "object") {
                                        toDisplay = this.showModal(this.state.images_modal.images[key], index, this.state.images_modal.images.length);
                                    }
                                    return toDisplay;
                                })
                            }
                        </Carousel>)
                    :
                        <Carousel className={"row"} arrows={false} renderButtonGroupOutside={true} customButtonGroup={<NewsCarousel/>} responsive={responsive} infinite={true} draggable={true} swipeable={true}>
                            {
                                Object.keys(this.state.images).map((key, index) => {
                                    var toDisplay = null;
                                    if (typeof this.state.images[key] === "object") {
                                        toDisplay = this.showCarousel(this.state.images[key], index)
                                    }
                                    return toDisplay
                                })
                            }
                        </Carousel>
                }
            </div>
        )
    }
}

export default CustomCarousel;