import React from 'react';
import '../css/Footer.css';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    render() {
        return (
            <>
                <div className="content-footer">
                    <div className="scout_footer">
                        <div className="scout1">
                            <div><span className="scout scoutFooter">scout</span> <span className="getscouted getScoutedFooter">get scouted</span></div>
                        </div>
                        <div className="scout2"><img src={require('./picto_noir.svg')} width="20" height="20" className="logo_footer2" alt=""/></div>
                    </div>
                    <div className="logo_footer">
                        <img src={require('./logo_noir.svg')} id="scrollSmooth" className="logo_footer1" alt=""/>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;
