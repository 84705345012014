import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import '../css/App.css';
import LandingPage from './LandingPage.js'
import InDev from './InDev.js'

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route exact path='/' component={LandingPage}/>
				<Route exact path='/ComingSoon' component={InDev} />
			</Switch>
	   	</BrowserRouter>
	);
}

export default App;
