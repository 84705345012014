import React from 'react';
import { PREFIX_IMG } from '../config/env';
import '../css/App.css';
import '../css/Header.css';
// import ModalTab from './ModalTab';
// import Content from './Content';
import Timeline from './Timeline';
import $ from "jquery";
//import smoothscroll from 'smoothscroll-polyfill';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    uploadJquery() {
        $(".scrolldown div").on("click", function() {
            var $target = $('html,body');
            $target.animate({scrollTop: $target.height()}, 1000);
            $('body').addClass('stop-scrolling')
        });

        $(".showcase").on("click", function() {
            var $target = $('html,body');
            $target.animate({scrollTop: $target.height()}, 1000);
            $('body').addClass('stop-scrolling')
        });

        $(".header-scouted").on("click", function() {
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).trigger('click').promise().then(function() {
                    $("#tab_scout").trigger('click');
                })
            });
        });

        $(".header-get_scouted").on("click", function() {
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).trigger('click').promise().then(function() {
                    $("#tab_getScouted").trigger('click');
                },)
            });
        });

        $(".header-about").on("click", function() {
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).trigger('click').promise().then(function(){
                    $("#tab_about").trigger('click');
                })
            });
        });

        $(".header-contact").on("click", function(){
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).delay(1000).trigger('click').promise().then(function(){
                    $("#tab_contact").trigger('click');
                })
            });
        });

        $(".header-network").on("click", function() {
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).trigger('click').promise().then(function(){
                    $("#tab_sNetwork").trigger('click');
                })
            });
        });

        $(".header-download_app").on("click", function(){
            $("html, body").animate({ scrollTop: $(document).height() }, 3000).promise().then(function() {
                $("#hamburger").delay(1000).trigger('click').promise().then(function(){
                    $("#tab_download").trigger('click');
                })
            });
        });
    }
    componentDidMount() {
        this.uploadJquery();
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(event){
        var body = document.body;
        let headerHeight = $('#header-top').height();
        let scrollY = Math.round(window.scrollY);

        //alert(body.scrollHeight)
        if (body.clientWidth >= 1200){
            if( $('#scrollSmooth').length && scrollY >= (headerHeight / 5) ) {
                $("html, body").stop().animate( { scrollTop: $('#scrollSmooth').offset().top }, 150);
                $('body').addClass('stop-scrolling')
            }
        }
    }

    render() {
        const { logo, institutional_video_mobile, institutional_video_tablet, institutional_video_desktop } = this.props;
        return (
          <>
            <div className="header-top" id="header-top">
                <div className="header-row">
                    <div className="header1">
                        {/* <a href="#search" className="left-side"></a> */}
                        <div className="right-side header-download_app">Download THE.app</div>
                    </div>
                    <div className="header-logo">
                        <img alt="logo" src={PREFIX_IMG + logo.url} className="logo1"/>
                    </div>
                    <nav className="nav nav-links">
                        <div className="nav-link active showcase">News</div>
                        <div className="nav-link header-about">About</div>
                        <div className="nav-link header-network">Social media</div>
                        <div className="nav-link header-scouted">Discover models</div>
                        <div className="nav-link header-get_scouted">Become a model</div>
                        <div className="nav-link header-contact">Contact</div>
                    </nav>
                </div>
                <div className="page_header">
                    <Timeline id="static-timeline" />
                    <div className="page_header1">
                        <div className="title">
                            <p>
                                <span>GROUND 0</span>
                            </p>
                        </div>
                        <div className="video1">
                            <video loop src={PREFIX_IMG + institutional_video_desktop.url} type="video/mp4" className="video_header" autoPlay muted fluid="false"/>
                            <video loop src={PREFIX_IMG + institutional_video_tablet.url} type="video/mp4" className="video_header_tablette" autoPlay muted fluid="false"/>
                            <video loop src={PREFIX_IMG + institutional_video_mobile.url} type="video/mp4" className="video_header_mobile" autoPlay muted fluid="false"/>
                        </div>
                    </div>
                </div>
                <div className="scrolldown">
                    <div className="scroll">
                        <div id="scrolldown" style={
                            {display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }>
                            <div style={{display: 'block'}}>
                                <p>scroll down</p>
                            </div>
                            <div style={{display: 'block'}}>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </>
        );
    }
}

export default Header;
