import React from 'react';
import '../css/Timeline.css';

class Timeline extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            custom_date: []
        }
        this.buildTimeline = this.buildTimeline.bind(this);
    }

    buildTimeline() {
        const nb_elem = this.props.nbElem - 2;
        let month = 1;
        for (let index = 1; index < nb_elem; index++) {
            if (index % 13 === 0)
                month = 1
            let day = Math.floor(Math.random() * 28) + 1;
            this.state.custom_date.push(('0' + day).slice(-2) + "/" + ('0' + month).slice(-2));
            month++;
        }
        this.setState({
            custom_date: this.state.custom_date.reverse()
        })
    }

    componentDidMount() {
        this.buildTimeline()
    }

    render() {
        const id = this.props.id;
        const content = this.state.custom_date.map(date =>
            <h2 key={date}>
                <p>{date}</p>
            </h2>
        );
        return (
            <>
                {
                    id === "static-timeline" ?
                        <div id={this.props.id} className="timeline_design">
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                            <h2>
                                <p>00/00</p>
                            </h2>
                        </div>
                    :
                        <div id={id}>
                            {content}
                        </div>
                }
            </>
        );
    }
}

export default Timeline;