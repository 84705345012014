import React from 'react';
import {APP_URL, PREFIX_IMG} from "../config/env";
import ReactMarkdown from "react-markdown";
import CustomCarousel from "./CustomCarousel";

const DisplayGrid = React.memo((props) => {
    /*
        This is a normal distribution of random (Gaussian)
        You need to set the min, max and skew

        skew is the deviation. The greater the value (> 1), the closer to the min
        and the smaller the value (<1) the closer to the max.
     */

    const gaussianRandom = (min, max, skew) => {
        let u = 0, v = 0;
        while(u === 0) u = Math.random() //Converting [0,1) to (0,1)
        while(v === 0) v = Math.random()
        let num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v )

        num = num / 10.0 + 0.5 // Translate to 0 -> 1
        if (num > 1 || num < 0)
            num = gaussianRandom(min, max, skew) // resample between 0 and 1 if out of range

        else{
            num = Math.pow(num, skew) // Skew
            num *= max - min // Stretch to fill range
            num += min // offset to min
        }
        return num

    }

    const display_post = (obj, index) => {
        //This list is needed to randomize position of element in grid
        const positions = [
            'start',
            'center',
            'end'
        ];
        const randomPortraitColumn = Math.round(gaussianRandom(1, 2, 1));
        const randomLandscapeColumn = Math.round(gaussianRandom(2, 3, 1));
        const randomPositionInColumn = Math.round(Math.random() * 2); //Depending on positions map length

        const isPortrait = obj?.images?.[0].height >= obj?.images?.[0].width ? true : false;

        const styleRandom = {
            gridColumn: isPortrait ? 'auto / span ' + randomPortraitColumn : 'auto / span ' + randomLandscapeColumn,
            gridRow: isPortrait ? 'auto / span ' + randomPortraitColumn : 'auto / span ' + randomLandscapeColumn,
            alignSelf: positions[randomPositionInColumn],
        }
        return (
            <div id={"container_" + index} key={index} className={"box-image " + (obj?.images?.[0].height >= obj?.images?.[0].width ? "post_portrait" : "post_landscape")} style={props.state ? {} : styleRandom}>
                <div className="model-card">
                    <p className="model-card__text">{obj?.author.split(/for | by/)[0]}</p>
                    <img id={"div_" + index} data-id={index} className={"image"} src={(PREFIX_IMG + obj?.images?.[0].formats?.large?.url) === "undefined" ? (PREFIX_IMG + obj?.images?.[0].url) : (PREFIX_IMG + obj?.images?.[0].formats?.large?.url)} onClick={props.todo} alt="" style={{cursor: "pointer"}}/>
                </div>
            </div>
        )
    }

    const display_article = (obj, index) => {
        return (
            <div id={"div_" + index} key={index} data-id={index} className={"print_array article"}>
                <div className="row">
                    <h2>
                        <span>Article</span>
                    </h2>
                </div>
                <div className="row content_component">
                    <div className="col-12 upper_content">
                        <div className="row">
                            <div className="col-6 no-padding"><p>{obj.article?.tag}</p></div>
                            <div className="col-6 component_date no-padding"><p>{obj.article.date.split("-")[2] + "/" + obj.article.date.split("-")[1]}</p></div>
                        </div>
                    </div>
                    <div className="col-12 lower_content">
                        <div className="row">
                            <h2>{obj.article.title}</h2>
                        </div>
                        <div className="row">
                            <ReactMarkdown
                                source={obj.article.content}
                                transformImageUri={uri =>
                                    uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const display_carousel = (obj, index) => {
        return (
            <div id={"div_" + index} key={index} data-id={index} className={"print_array carousel"}>
                <div className="row">
                    <h2>
                        <span>News</span>
                    </h2>
                </div>
                <div className="row middle_content">
                    <div className="col-12 col-sm-12 upper_content">
                        <div className="row">
                            <div className="col-6 no-padding"><p>{obj.tag}</p></div>
                            <div className="col-6 component_date no-padding"><p>{obj.date.split("-")[2] + "/" + obj.date.split("-")[1]}</p></div>
                        </div>
                    </div>
                    <div className="col-12 lower_content">
                        <CustomCarousel {...obj.images_carousel} type="news" nbElem={5} />
                    </div>
                </div>
                <div className="row content_component">
                    <div className="col-12 upper_content">
                        <div className="row">
                            <div className="col-6 no-padding"><p>{obj.infos.tag}</p></div>
                            <div className="col-6 component_date no-padding"><p>{obj.infos.date.split("-")[2] + "/" + obj.infos.date.split("-")[1]}</p></div>
                        </div>
                    </div>
                    <div className="col-12 lower_content style-3">
                        <div className="row">
                            <h2>{obj.infos.title}</h2>
                        </div>
                        <div className="row">
                            <ReactMarkdown
                                source={obj.infos.content}
                                transformImageUri={uri =>
                                    uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                }
                                className="scrollbarFocus"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const display_focus = (obj, index) => {
        return (
            <div id={"div_" + index} key={index} data-id={index} className={"print_array focus"}>
                <div className="row">
                    <h2>
                        <span>Focus</span>
                    </h2>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6 video_div">
                        <div className="row middle_content">
                            <div className="col-12 lower_content">
                                <video loop src={PREFIX_IMG + obj?.video?.url} type="video/mp4" className="" autoPlay muted fluid="false"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 video_div">
                        <div className="row content_component">
                            <div className="col-12 upper_content">
                                <div className="row">
                                    <div className="col-6 no-padding"><p>{obj.infos.tag}</p></div>
                                    <div className="col-6 component_date no-padding"><p>{obj.infos.date.split("-")[2] + "/" + obj.infos.date.split("-")[1]}</p></div>
                                </div>
                            </div>
                            <div className="col-12 lower_content">
                                <div className="row">
                                    <h2>{obj.infos.title}</h2>
                                </div>
                                <div className="row">
                                    <ReactMarkdown
                                        source={obj.infos.content}
                                        transformImageUri={uri =>
                                            uri.startsWith("http") ? uri : `${APP_URL}${uri}`
                                        }
                                        className="scrollbarFocus"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const display_video = (obj, index) => {
        return (
            <div id={"div_" + index} key={index} data-id={index} className={"video"} onClick={props.todo}>
                <div className="video-card">
                    <p className="video-card__text">{obj?.video_loop?.name.split(".")[0]}</p>
                    <video loop src={PREFIX_IMG + obj?.video_loop?.url} type="video/mp4" className="" autoPlay muted fluid="false" style={{cursor: "pointer"}}/>
                </div>
            </div>
        )
    }

    console.log(props.news);
    return(
        <>
            {

                Object.keys(props.news).map((key, index) => {
                    // console.log(index, typeof news[key], news[key])
                    var toDisplay = null;
                    if (props.news[key]?.__component === "page.posts") {
                        toDisplay = display_post(props.news[key], index)
                    }
                    else if (props.news[key]?.__component === "page.actuality") {
                        toDisplay = display_article(props.news[key], index)
                    }
                    else if (props.news[key]?.__component === "page.carousel") {
                        toDisplay = display_carousel(props.news[key], index)
                    }
                    else if (props.news[key]?.__component === "page.focus") {
                        toDisplay = display_focus(props.news[key], index)
                    }
                    else if (props.news[key]?.__component === "page.video") {
                        toDisplay = display_video(props.news[key], index)
                    }

                    return toDisplay;
                })
            }
        </>
    )
});

export default DisplayGrid;