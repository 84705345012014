import React from 'react';

class InDev extends React.Component {
    render() {
        return (
            <>
                <div>
                     <video loop style={{height: "98vh", width: "100vw"}}  src={require('./13_P.mp4')} type="video/mp4" autoPlay muted fluid="false"/>
                </div>
            </>
        );
    }
}

export default InDev;
